import { BarsOutlined, BlockOutlined, CheckCircleTwoTone, EyeOutlined, FileTextOutlined, IssuesCloseOutlined, LineChartOutlined, PlusCircleOutlined, SearchOutlined, SoundOutlined, UploadOutlined, WarningTwoTone } from '@ant-design/icons';
import { Button, Card, Col, Input, Progress, Radio, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import '../pages.scss'
import { FilterDropdownProps } from 'antd/lib/table/interface';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import { DrawerContext } from '../../../store/DrawerContext/DrawerContext';
import { useFetch } from '../../../hooks/useFetch/useFetch';
import { getProjectStorage } from '../../../store/storageContext';
import ColorScore from '../../../common/utils/ColorScore';
import { Location } from '../../components/model/business/FieldsGroup';
import { Synchronized } from '../../components/design/Synchronized/Synchronized';
import { BlockContentKpis } from '../menuReports/blocks/BlockContentKpis';
import { SCHEDULEDJOB } from '../../../common/constants/services';
import GooglePublishPanel from './LocalPosts/GooglePublishPanel';
import TableLZM from '../../components/design/table/TableLZM';
import ImagePublishPanel from './LocalPosts/ImagePublishPanel';
import { useIsAuthorized } from '../common/roles/Roles';

export const GooglePublishContent = () => {

	const { t } = useTranslation();
	
	const { groupAccount } = useAuth();
	const [publishMassivePanelVisible, setPublishMassivePanelVisible] = useState(false);	
	const [publishOnePanelVisible, setPublishOnePanelVisible] = useState(false);	
	const [imagePublishPanelVisible, setImagePublishPanelVisible] = useState(false);
	const [updateData, setUpdateData] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
	const [selectedLocation, setSelectedLocation] = useState<any>([]);
	const isAuthorized = useIsAuthorized();
	const { setLocationDrawer } = useContext(DrawerContext)
	const { setLogsDrawer } = useContext(DrawerContext);
	const { setPostsDrawer } = useContext(DrawerContext);
	const { setScheduledPostsDrawer } = useContext(DrawerContext);
	
	const history = useHistory();	
	const location = useLocation();

	const publish = async (selectedLocation: any) => {
				
		setSelectedRowKeys([selectedLocation.locationId]);		
		setSelectedLocation(selectedLocation);
		setPublishOnePanelVisible(true);		
	};

	const { data, isLoading }: any = useFetch(
		`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/locations/posts',
		{},
		[updateData],
	);

	const columns: any = [
		{
			title: 'Establecimiento',
			key: 'location',						
			sorter: (a: any, b: any) => a.locality?.localeCompare(b.locality),
			filterDropdown: ({
			  setSelectedKeys,
			  selectedKeys,
			  confirm,			  
			}: FilterDropdownProps) => {
			  return (
				<Input
				  autoFocus
				  //placeholder="Filtro"
				  value={selectedKeys[0]}
				  onChange={(e: any) => {
					setSelectedKeys(e.target.value ? [e.target.value] : []);
					confirm({ closeDropdown: false });
				  }}
				  onPressEnter={() => {
					confirm();
				  }}
				  onBlur={() => {
					confirm();
				  }}
				></Input>
			  );
			},
			filterIcon: () => {
			  return <SearchOutlined />;
			},
			onFilter: (value: any, record: any) => {
				return record.title?.toLowerCase().includes(value?.toLowerCase()) 
				|| record.administrativeArea?.toLowerCase().includes(value?.toLowerCase())
				|| record.locality?.toLowerCase().includes(value?.toLowerCase());
			},
			render: (text: string, record: any) => (
				<Location record={record} onChange={() => {setLocationDrawer(record.composeId);}}/>									
			),
		},	
		{
			title: 'Publicado',
			key: 'numberOfPost',
			align: 'center',
			dataIndex: 'numberOfPost',
			render: (text: string, record: any) => (
				record.numberOfPost ? 					
					<Button type='link' onClick={()=>{setPostsDrawer(record.composeId)}} size='small' >    
						<span>{record.numberOfPost} posts</span>
					</Button>									
					:
					<i style={{color:'lightgrey'}}>Sin posts</i>
			),
			sorter: (a: any, b: any) => b.numberOfPost - a.numberOfPost
		},
		{
			title: 'Programado',
			//align: 'center',
			key: 'numberOfScheduled',
			dataIndex: 'numberOfScheduled',
			render: (text: string, record: any) => (
				record.numberOfScheduled ? 								
					<Button type='link' onClick={()=>{setScheduledPostsDrawer(record.composeId)}} size='small' >    						
						<span>{record.numberOfScheduled} programaciones</span>					
					</Button>											
					:
					<i style={{color:'lightgrey'}}>Sin Programaciones</i>
			),
			sorter: (a: any, b: any) => b.numberOfScheduled - a.numberOfScheduled
		},
		{
			title: '#Días sin publicar',
			//align: 'center',
			key: 'daysWithoutPost',
			dataIndex: 'daysWithoutPost',
			render: (text: string, record: any) => (
				record.daysWithoutPost && <ColorScore value={record.daysWithoutPost} />					
			),
			sorter: (a: any, b: any) => b.daysWithoutPost - a.daysWithoutPost
		},
		{
			title: '',
			key: 'locationId',			
			render: (text: string, record: any) => (
				<div style={{display:'flex', justifyContent:'space-between'}}>	
					<Button onClick={()=>{publish(record)}} size='small' style={{ borderRadius: '20px', background:'var(--primary)',  border:`solid 1.3px RoyalBlue`, color: "white" }}>    
						<PlusCircleOutlined style={{ color: 'white' }}/> Publicar	
					</Button>					
				</div>
			)
		}
	]

	const onSelectChange = (selectedRowKeys: any) => {
		setSelectedRowKeys(selectedRowKeys);
		setIndeterminate(true);

	}

	const [indeterminate, setIndeterminate] = useState(false);

	const onSelectAllCustom = (selected: boolean) => {
		setSelectedRowKeys(selected ? data.content.map((row: any) => row.locationId) : []);
		setIndeterminate(false);
	}


	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		columnTitle: <Checkbox indeterminate={indeterminate} onChange={(e) => onSelectAllCustom(e.target.checked)}></Checkbox>,
	}

	const hasSelected = selectedRowKeys.length > 0

	/* Contenidos - Gestión masiva */

	return (
		<>
			<Card className='main-card-content'>

				<div style= {{width:'100%', display:'flex', justifyContent:'space-between'}}>
					<h1 className='title-card'>Gestión de Publicaciones</h1>
					<Button type='link' onClick={() => setLogsDrawer(true)}>
						Ver Registro
					</Button>		
				</div>
				
				<div className="card-container">
					{data.kpis && <BlockContentKpis kpis={data.kpis}/>}
				</div>				
				<div style={{ minHeight: '35px', marginBottom: '10px' }}>
					<Synchronized service={[SCHEDULEDJOB.GBP_POSTS]} />
					
					<div style= {{width:'100%', display:'flex', justifyContent:'space-between'}}>
						<div style= {{display:'flex', alignItems:'center'}}>
							<div>
								{									
									<b style={{ color: 'black', font: '26px' }}>Masivo {`${selectedRowKeys.length} de ${data.content?.length}`}:</b>											
								}					
							</div>																			
							<Button type='link' onClick={() => setPublishMassivePanelVisible(true)} disabled={!hasSelected || !isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_CONTENT_EDIT'])}>
								<b><PlusCircleOutlined /> {t('dictionary.publish')}</b>
							</Button>									
							<Button type='link' onClick={() => setImagePublishPanelVisible(true)} disabled={!hasSelected || !isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_CONTENT_EDIT'])}>
								<b><UploadOutlined /> {t('dictionary.photo')}</b>
							</Button>				
						</div>																										
					</div>											
				</div>				

				<TableLZM
					rowKey='locationId'
					scroll={{ x: true }}
					rowSelection={rowSelection}
					columns={columns}
					dataSource={data.content}
					loading={isLoading}
				/>

				{publishMassivePanelVisible && (
					<GooglePublishPanel
						selectedRowKeys={selectedRowKeys}
						visible={publishMassivePanelVisible}
						onFinish={(isScheduled: boolean) => {
							setPublishMassivePanelVisible(false);
							setUpdateData(!updateData);
							isScheduled ? 
								history.push('/contenidos/programados', { from: location })
								:
								setTimeout(() => {
									setLogsDrawer(true);
								}, 500);
						}}
						onCancel={() => {
							setPublishMassivePanelVisible(false);
						}}
					/>)
				}
				
				{publishOnePanelVisible && (
					<GooglePublishPanel
						selectedRowKeys={selectedRowKeys}
						location={selectedLocation}
						visible={publishOnePanelVisible}
						onFinish={(isScheduled: boolean) => {
							setPublishOnePanelVisible(false);
							setUpdateData(!updateData);
							isScheduled ? 
								history.push('/contenidos/programados', { from: location })
								:
								setTimeout(() => {
									setLogsDrawer(true);
								}, 500);
						}}
						onCancel={() => {
							setPublishOnePanelVisible(false);
						}}
					/>)
				}
				{imagePublishPanelVisible && (
					<ImagePublishPanel
						selectedRowKeys={selectedRowKeys}
						visible={imagePublishPanelVisible}
						onFinish={() => {
							setImagePublishPanelVisible(false);
							setUpdateData(!updateData);
							setTimeout(() => {
								setLogsDrawer(true);
							}, 500);
						}}
						onCancel={() => {
							setImagePublishPanelVisible(false);
						}}
					/>)
				}
			</Card>

		</>
	)
}