import { Checkbox, Form, Input, Modal, Switch } from 'antd';
import 'moment/locale/es';
import { useAuth } from '../../../../../store/AuthContext/AuthContext';
import { getProjectStorage } from '../../../../../store/storageContext';
import { authFetch } from '../../../../../common/request/authFetch';
import { useState } from 'react';

export const NewKeywordModal = (props: {locationId: string, hasWeb: boolean, onFinish: any}) => {

	const [domainKeyword, setDomainKeyword] = useState(props.hasWeb);
	const [form] = Form.useForm();
	const { groupAccount } = useAuth();

	const onFormFinish = async (values: any) => {

		await authFetch(`${process.env.REACT_APP_API}/projects/${getProjectStorage()}/seo/${groupAccount}/${props.locationId}/terms`, { method: 'POST', data: values });
		props.onFinish();
	}

	return (

		<Modal open={true} onOk={() => form.submit()} onCancel={props.onFinish} afterClose={form.resetFields}>

			<h2>Añadir keyword</h2>
			
			<br/>
			<Form layout='horizontal' form={form} onFinish={onFormFinish} initialValues={{domainScope: domainKeyword}}>
				<Form.Item
					rules={[{ required: true, message:'Por favor, ingrese la keyword' }]}
					name='keyword'
					label='Keyword'>
					<Input placeholder='Categoría, producto, servicios,...'/>
				</Form.Item>				
				<Form.Item name='domainScope' valuePropName="checked" label='Alcance'>
					<Switch disabled={!props.hasWeb} checkedChildren="Ubicaciones del dominio" unCheckedChildren="Solo ubicación actual" onChange={(e) => {setDomainKeyword(e)}}/>
				</Form.Item>	
				
			</Form>			
		</Modal>
	)
}
