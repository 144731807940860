import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, LikeOutlined, LoadingOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Comment, Form, Input, Modal, Spin, message } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { authFetch } from "../../../../../common/request/authFetch";
import { getProjectStorage } from "../../../../../store/storageContext";
import { STATUS } from "../../../../../common/constants/statusFAQAndReviews";
import { ButtonLZ } from "../../../design/Buttons/ButtonLZ";
import ReviewComment from "../../../../pages/menuReviews/3_1_Reviews/Reviews/ReviewComment";
import { EmojiPickerCustom } from "../../../design/EmojiPicker/EmojiPickerCustom";
import { useIsAuthorized } from "../../../../pages/common/roles/Roles";

export const ReviewAnswer = ({ getResponseIa, submitReply, replyForm, isLoadingIa, review, onFinish }: any) => {

    const { t } = useTranslation();
    const isAuthorized = useIsAuthorized();
    const [status, setStatus] = useState('');
    const [showPicker, setShowPicker] = useState(false);

    const [cursorIndex, setCursorIndex] = useState(0);

    const handleFocus = (event:any) => {
        setShowPicker(false);        
    }

    const handleBlur = (event:any) => {
        setCursorIndex(event.target.selectionStart);
    };

    const handleEmojiClick = (emoji:any) => {    

        const textArea = replyForm.getFieldValue('comment');
        const textAreaWithEmoji = 
                textArea.slice(0, cursorIndex) +
                emoji.native +
                textArea.slice(cursorIndex);
        replyForm.setFieldsValue({ comment: textAreaWithEmoji });
    };    

    const deleteReply = async (): Promise<void> => {
        const data = {
            reviewId: review.name
        };
        await authFetch(
            `${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/reviews/reply',
            { method: 'DELETE', data }
        )
            .then((res) => {
                if (res) {
                    message.success(t('view.forms.modal.reviews.delete.success', { name: review.reviewerDisplayName }), 3);
                    onFinish();
                } else {
                    message.error(t('view.forms.modal.reviews.delete.error', { name: review.reviewerDisplayName }), 3);
                }
            })
    }    
    
    return (
        <>
            <Comment
                actions={
                    isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_REVIEWS_EDIT']) && status !== STATUS.EDITING ? ([
                        <a className='review-btn-grey'
                            onClick={() => setStatus('Editing')}>
                            <EditOutlined style={{ marginRight: '2x' }} />
                            {t('dictionary.edit')}
                        </a>,
                        <a className='review-btn-grey'
                            onClick={() => {
                                Modal.confirm({
                                    title: t('reviews.page.reply.tabs.reviews.delete.confirm'),
                                    okText: t('dictionary.confirm'),
                                    cancelText: t('dictionary.cancel'),
                                    onOk: () => deleteReply()
                                })
                            }}
                        >
                            <DeleteOutlined style={{ marginRight: '2px' }} />
                            {t('dictionary.delete')}
                        </a>
                    ]) : undefined
                }
                author={
                    <div style={{ display: 'flex' }}>

                        <div style={{ color: 'black', fontSize: '14px' }}>
                            <Avatar style={{ backgroundColor: '#87d068', marginRight: '10px' }} icon={<UserOutlined />} />
                            {t('dictionary.owner')}
                        </div>

                    </div>
                }
                datetime=
                {status === STATUS.EDITING &&
                    <ButtonLZ
                        onClick={() => {
                            getResponseIa();
                        }}
                        style={{ width: '40px', marginRight:'30px' }}
                        size='small' color='dark-white'>{isLoadingIa ? <Spin indicator={<LoadingOutlined spin />} /> : t('reviews.page.reply.review.btn.ia')}</ButtonLZ>
                }

                content={
                    status === STATUS.EDITING ? (
                        <Form layout='vertical'
                            form={replyForm}
                            onFinish={submitReply}
                            initialValues={{comment : review.reviewReplyComment?.originalText ? review.reviewReplyComment?.originalText : review.reviewReplyComment?.text}}
                        >
                            <Form.Item name='comment'>
                                <Input.TextArea
                                    className='comment-answer'                            
                                    style={{ width: '92%' }}
                                    rows={6}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                />
                            </Form.Item>
                            <div>
                                <a className='review-btn'
                                    onClick={() => { replyForm.resetFields(); setStatus(''); }}
                                >
                                    <CloseOutlined /> {t('dictionary.cancel')}
                                </a>
                                <a className='review-btn'
                                    onClick={() => { replyForm.submit(); setStatus(''); }}
                                >
                                    <CheckOutlined /> {t('dictionary.accept')}
                                </a>
                            </div>
                            <EmojiPickerCustom  handleEmojiClick={handleEmojiClick} showPicker={showPicker} setShowPicker={setShowPicker}/>
                        </Form>
                    ) : (
                        !!review.reviewReplyComment && (
                            <ReviewComment comment={review.reviewReplyComment} />
                        )
                    )
                }
            />
        </>


    )
}