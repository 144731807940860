import { CalendarOutlined, LockFilled, NotificationOutlined, RocketOutlined, TagOutlined } from '@ant-design/icons';
import { Tag, Button, message, Modal, Tabs, Input, Alert, DatePicker, Form, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { getProjectStorage } from '../../../../store/storageContext';
import { authFetch } from '../../../../common/request/authFetch';
import FormItemCallToAction from '../../../components/model/business/forms/FormItemCallToAction';
import FormItemEvent from '../../../components/model/business/forms/FormItemEvent';
import FormItemOffer from '../../../components/model/business/forms/FormItemOffer';
import FormItemSummary from '../../../components/model/business/forms/FormItemSummary';
import FormItemTitle from '../../../components/model/business/forms/FormItemTitle';
import FormItemUploadPhoto from '../../../components/model/business/forms/FormItemUploadPhoto';
import { Location } from '../../../components/model/business/FieldsGroup';
import { FormItemSchedule } from '../../../components/model/business/forms/FormItemSchedule';

const GooglePublishPanel = ({
	selectedRowKeys,
	location,
	visible,
	onCancel,
	onFinish,
}: any) => {	

	const { groupAccount, profile } = useAuth();
	const [form1] = Form.useForm();
	const [form2] = Form.useForm();
	const [form3] = Form.useForm();
	const { t } = useTranslation();

	const scheduleDate1 = Form.useWatch('scheduleDate', form1);
	const scheduleDate2 = Form.useWatch('scheduleDate', form2);
	const scheduleDate3 = Form.useWatch('scheduleDate', form3);

	let hasRoleScheduled: boolean = profile.roles.includes('ROLE_ADMIN') || profile.roles.includes('ROLE_SCHEDULED_POSTS');

	const submitStandard = async (values: any) => {

		const data = {
			type: "STANDARD",
			scope: location ? "LOCATION" : "SELECTED_LOCATIONS",
			locationTitle: location?.title,
			summary: values.summary,
			scheduleDate: values.scheduleDate,
			callToActionType: values.callToActionType,
			callToActionURL: values.callToActionURL,		
			callToActionURLLocal: values.callToActionURLLocal,	
			locationsNames: selectedRowKeys,
			photosURL: values.photo != null ? 
			values.photo.fileList.map((file: { url: any; }) => file.url)
			:
			[]
		};
		massivePublish(data, values.scheduleDate!!);			
	};

	const submitOffer = async (values: any) => {
		
		const data = {					
			type: "OFFER",
			scope: location ? "LOCATION" : "SELECTED_LOCATIONS",
			locationTitle: location?.title,
			summary: values.summary,
			scheduleDate: values.scheduleDate,
			eventTitle: values.title,
			eventScheduleStart: values.dateRange[0].format('YYYY-MM-DDTHH:mm[Z]'),
			eventScheduleEnd: values.dateRange[1].format('YYYY-MM-DDTHH:mm[Z]'),	
			offerCouponCode: values.code,
			offerRedeemOnlineUrl: values.redeemOnlineUrl,
			offerTermsConditions: values.termsAndConditions,
			locationsNames: selectedRowKeys,
			photosURL: values.photo != null ? 
			values.photo.fileList.map((file: { url: any; }) => file.url)
			:
			[]
		};		
		massivePublish(data, values.scheduleDate!!);				
	};

	const submitEvent = async (values: any) => {		
	
		const data = {
			type: "EVENT",
			scope: location ? "LOCATION" : "SELECTED_LOCATIONS",
			locationTitle: location?.title,
			summary: values.summary,
			scheduleDate: values.scheduleDate,
			eventTitle: values.title,
			eventScheduleStart: values.dateRange[0].format('YYYY-MM-DDTHH:mm[Z]'),
			eventScheduleEnd: values.dateRange[1].format('YYYY-MM-DDTHH:mm[Z]'),
			callToActionType: values.callToActionType,
			callToActionURL: values.callToActionURL,				
			locationsNames: selectedRowKeys,
			photosURL: values.photo != null ? 
			values.photo.fileList.map((file: { url: any; }) => file.url)
			:
			[]
		};
		massivePublish(data, values.scheduleDate!!);			
	};

	const massivePublish = async (data : any, isScheduled: boolean) => {
		await authFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/locations/localPost', { method: 'POST', data: data })
		.then((res) => {
			if (res) {	
				isScheduled ? 
				message.loading('Se ha programado esta publicación', 5)
				:
				message.loading(t('view.forms.modal.bulk.triggered'), 5);
			} else {
				message.error(t('view.forms.modal.bulk.error'), 5);							
			}
		});
		onFinish(isScheduled);	
	}

  return (
		<Modal open={visible} onCancel={onCancel} footer={null} width={'60%'} centered style={{margin:'5px'}} bodyStyle={{minHeight:'90vh'}}> 
			
			<div style={{position:'relative'}}>
				{location ? 
					<>
						<h2 style={{paddingTop:'5px', paddingBottom:'5px', paddingRight:'15px'}}>Nueva Publicación</h2>
						<div style={{margin:'20px', border:'solid 4px lavender', borderRadius:'10px'}}><Location record={location}/></div>
					</>
					:
					<>
						<h2 style={{paddingTop:'5px', paddingBottom:'5px', paddingRight:'15px'}}>Nueva Publicación Masiva</h2>
						<Alert message={<div>{selectedRowKeys.length} locales seleccionados</div>} type="info" showIcon />
					</>
					}
			</div>

			<Tabs defaultActiveKey="1" type="line" style={{overflow:'visible'}}>
				<Tabs.TabPane id='tabs' tab={<span className='title-tabPane'><NotificationOutlined/>{t('dictionary.novelty')}</span>} key="1">
					<Form layout='vertical' form={form1} onFinish={submitStandard}>
						<FormItemSummary isRequired={true} form={form1} type='Novedad'/>
						<FormItemUploadPhoto maxPhotos={1}/>				
						<FormItemCallToAction/>	
						<Button style={{float:'right', clear:'right', margin:'0px 10px', width:'180px'}} shape='round' type='primary' onClick={form1.submit} disabled={!scheduleDate1}>
							<CalendarOutlined/> Agendar 
						</Button>
						{hasRoleScheduled ?
							<FormItemSchedule/>
							:
							<Tag color='purple' style={{float:'right', margin:'10px'}}><b><LockFilled/> Tu plan no te permite agendar</b></Tag>
						}
						<Button style={{float:'right', clear:'right', margin:'0px 10px', width:'180px'}} shape='round' type='primary' onClick={form1.submit} disabled={scheduleDate1}>
							<RocketOutlined/> Publicar
						</Button>
					</Form>															
				</Tabs.TabPane>
					
				<Tabs.TabPane tab={<span className='title-tabPane'><TagOutlined/>{t('dictionary.offer')}</span>} key="2">
					<Form layout='vertical' form={form2} onFinish={submitOffer}>												
						<FormItemTitle/>
						<FormItemSummary isRequired={false} form={form2} type='Oferta'/>
						<FormItemEvent/>
						<FormItemUploadPhoto maxPhotos={1}/>
						<FormItemOffer/>
						<Button style={{float:'right', clear:'right', margin:'0px 10px', width:'180px'}} shape='round' type='primary' onClick={form1.submit} disabled={!scheduleDate2}>
							<CalendarOutlined/> Agendar 
						</Button>
						{hasRoleScheduled ?
							<FormItemSchedule/>
							:
							<Tag color='purple' style={{float:'right', margin:'10px'}}><b><LockFilled/> Tu plan no te permite agendar</b></Tag>
						}
						<Button style={{float:'right', clear:'right', margin:'0px 10px', width:'180px'}} shape='round' type='primary' onClick={form2.submit} disabled={scheduleDate2}>
							<RocketOutlined/> Publicar
						</Button>
					</Form>
				</Tabs.TabPane>

				<Tabs.TabPane tab={<span className='title-tabPane'><CalendarOutlined/>{t('dictionary.event')}</span>} key="3">
					<Form layout='vertical' form={form3} onFinish={submitEvent}>
						<FormItemTitle/>
						<FormItemSummary isRequired={false} form={form3} type='Evento' />
						<FormItemEvent/>
						<FormItemCallToAction/>
						<FormItemUploadPhoto maxPhotos={1}/>
						<Button style={{float:'right', margin:'0px 10px', width:'180px'}} shape='round' type='primary' onClick={form3.submit} disabled={!scheduleDate3}>
							<CalendarOutlined/> Agendar 
						</Button>						
						{hasRoleScheduled ?
							<FormItemSchedule/>
							:
							<Tag color='purple' style={{float:'right', margin:'10px'}}><b><LockFilled/> Tu plan no te permite agendar</b></Tag>
						}						
						<Button style={{float:'right', clear:'right', margin:'0px 10px', width:'180px'}} shape='round' type='primary' onClick={form3.submit} disabled={scheduleDate3}>
							<RocketOutlined/> Publicar
						</Button>
					</Form>
				</Tabs.TabPane>
			</Tabs>			
		</Modal>
	)
}

export default GooglePublishPanel;