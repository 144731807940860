import { Card, Tabs } from 'antd';
import { QuestionCircleOutlined, SettingOutlined, StarOutlined } from '@ant-design/icons';
import { useState } from 'react';
import './GoogleReviews.css';
import '../../pages.scss';
import ReviewsContainer from '../../../components/model/reviews/reviews/ReviewsContainer';
import { ButtonLZ } from '../../../components/design/Buttons/ButtonLZ';
import { Synchronized } from '../../../components/design/Synchronized/Synchronized';
import { SCHEDULEDJOB } from '../../../../common/constants/services';
import { ConfigureIADrawer } from './Reviews/drawer/ConfigureIADrawer';
import { useIsAuthorized } from '../../common/roles/Roles';

export const GoogleReviews = () => {

	const isAuthorized = useIsAuthorized();
	const [openDrawer, setOpenDrawer] = useState(false)

	return (
		<>
			<Card className='main-card-content' >
				<h1 className='title-card'>{'Responder Reseñas'}</h1>

				<Synchronized service={[SCHEDULEDJOB.GBP_REVIEWS]} />

				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					{
					isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_MODULE_REVIEWS']) && 
						<ButtonLZ onClick={() => { setOpenDrawer(true) }} /*style={{ color: 'purple', borderColor: 'purple', backgroundColor: 'Lavender' }}*/>
							<div><SettingOutlined /> Configurar IA</div>
						</ButtonLZ>
					}
				</div>

				<ReviewsContainer />
				{
					openDrawer && <ConfigureIADrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
				}
			</Card>
		</>
	)
}
