import { CheckOutlined, CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { Card, Rate, Comment, Form, Tag, Input, Spin, message, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import reviews from '../../../../../assets/reseñas-empty-component.png'
import "../ReviewsAndFaQ.css"
import { STATUS } from "../../../../../common/constants/statusFAQAndReviews";
import { ButtonLZ } from "../../../../components/design/Buttons/ButtonLZ";
import { authRequest } from "../../../../../common/request/authRequest";
import { getProjectStorage } from "../../../../../store/storageContext";
import { authFetch } from "../../../../../common/request/authFetch";
import { useAuth } from "../../../../../store/AuthContext/AuthContext";
import ReviewComment from "./ReviewComment";
import { ReviewAnswer } from "../../../../components/model/reviews/reviews/ReviewAnswer";
import { EmojiPickerCustom } from "../../../../components/design/EmojiPicker/EmojiPickerCustom";
import { useIsAuthorized } from "../../../common/roles/Roles";
import moment from "moment";


export default function CardDetailsReview({ reviewId, onFinish, drawer = false }: any) {  

  const [status, setStatus] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [review, setReview] = useState<any>(null);
  const [refreshReview, setRefreshReview] = useState(true);
  const [replyForm] = Form.useForm();
  const { t } = useTranslation();
  const { groupAccount } = useAuth();
  const isAuthorized = useIsAuthorized();

  const submitReply = async ({ comment }: { comment: string }): Promise<void> => {

    const data = {
      comment,
      reviewId: review.name
    };
    await authFetch(
      `${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/reviews/reply',
      { method: 'PUT', data: data }
    )
      .then((res) => {
        if (res) {
          message.success(t('view.forms.modal.reviews.reply.success', { name: review.reviewerDisplayName }), 3);
          //debugger;
          setRefreshReview(!refreshReview);
          setStatus('');
          onFinish();
        } else {
          message.error(t('view.forms.modal.reviews.reply.error', { name: review.reviewerDisplayName }), 3);
        }
      });
  };


  useEffect(() => {

    setStatus('')
    replyForm.resetFields();

    if (reviewId !== null) {
      authRequest(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + reviewId + '/review', {}).then((data) => {
        setReview(data)
      })
    }
  }, [reviewId, refreshReview, groupAccount])

  const daysAgo = (date: string): number => {
    const oneDay: number = 24 * 60 * 60 * 1000
    return Math.round(Math.abs((Date.now() - new Date(date).getTime()) / oneDay));
  };

  const getResponseIa = async () => {

    setIsLoading(true)
    await authRequest(`${process.env.REACT_APP_API}/${groupAccount}/chat?reviewerName=${encodeURIComponent(review?.reviewerDisplayName)}&starRating=${review?.starRating}&content=${encodeURIComponent(review?.comment.originalText ? review?.comment.originalText : review?.comment.text)}&locationId=${review.locationId}`, {})
    .then((data) => {
      handleOpenTextArea(data)
      setIsLoading(false)
    }).catch((error) => {
      setIsLoading(false)
    });
  }

  const handleOpenTextArea = (responseText: any) => {
    replyForm.setFieldsValue({ comment: responseText });
  };


  const [showPicker, setShowPicker] = useState(false);

  const [cursorIndex, setCursorIndex] = useState(0);

  const handleFocus = (event:any) => {
      setShowPicker(false);        
  }

  const handleBlur = (event:any) => {
      setCursorIndex(event.target.selectionStart);
  };

  const handleEmojiClick = (emoji:any) => {    

      const textArea = replyForm.getFieldValue('comment');
      const textAreaWithEmoji = 
              textArea.slice(0, cursorIndex) +
              emoji.native +
              textArea.slice(cursorIndex);
      replyForm.setFieldsValue({ comment: textAreaWithEmoji });
  };    

  return (
    <div className={`${!drawer && 'review-faq-container'}`}
      style={{ background: review ? (!review?.reviewReplyComment ? 'rgba(255, 249, 237, 1)' : 'rgba(236, 245, 243, 1)') : 'white' }}
    >
      {review ? (
        <Card
          className='card-layout'
          size='small'
          extra={
            <Rate
              style={{ fontSize: '18px' }}
              disabled={true}
              allowHalf
              value={review.starRating}
            />
          }
          title={
            <div>
                <span>{review.title}</span>
                {review.addressLine ? (
                    <p style={{ margin: '0px', fontSize: '14px', fontWeight: 600 }}>
                        {review.addressLine}, {review.locality}
                    </p>
                ) : (
                    <p style={{ margin: '0px', color: 'grey', fontStyle: 'italic', fontSize: '14px' }}>
                        Sin ubicación
                    </p>
                )}
            </div>
        }        
          headStyle={{ borderRadius: '10px', background: !review.reviewReplyComment ? 'rgba(255, 249, 237, 1)' : 'rgba(236, 245, 243, 1)' }}
          bodyStyle={{ borderRadius: '10px', background: !review.reviewReplyComment ? 'rgba(255, 249, 237, 1)' : 'rgba(236, 245, 243, 1)' }}
        >
          <Comment
            author={<div style={{ color: 'black', fontSize: '14px' }}>{review.reviewerDisplayName}</div>}
            avatar={review.reviewerProfilePhotoUrl}
            content={
              !!review.comment && <ReviewComment comment={review.comment} />
            }
            datetime={
              <small style={{ color: 'gray', display:'flex', justifyContent:'space-between' }}>
                {
                  daysAgo(review.updateTime) > 1 ?
                      <div>
                          <Tooltip title={<>
                                              <div>Creada el {moment(review.createdTime).format('ll')}</div>
                                              {!moment(review.createdTime).isSame(moment(review.updateTime)) && <div>Actualizada el {moment(review.updateTime).format('ll')}</div>}
                                          </>}>
                              {moment(review.updateTime).fromNow()}
                          </Tooltip>
                      </div>
                      :
                    <Tag color={'green'} style={{ borderRadius: '10px' }}>{t('dictionary.new.female').toUpperCase()}</Tag>
                }
              </small>
            }>
            {review.reviewReplyComment && <ReviewAnswer submitReply={submitReply} replyForm={replyForm} getResponseIa={getResponseIa} isLoadingIa={isLoading} review={review} onFinish={() => {setRefreshReview(!refreshReview); onFinish()}} />}
            {
              isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_REVIEWS_EDIT']) && !review.reviewReplyComment && status !== STATUS.REPLYING &&
              <a className='review-btn'
                onClick={() => setStatus('Replying')} >
                <span className='material-symbols-outlined review-btn-icon'>reply</span>
                {t('dictionary.reply')}
              </a>
            }
            {!review.reviewReplyComment && status === STATUS.REPLYING &&
              <div style={{ padding: '5px 25px 25px 25px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <small style={{ color: 'grey' }}>{t('dictionary.ownerReply')}</small>
                  <ButtonLZ
                    onClick={() => {
                      if (!isLoading) {
                        getResponseIa();
                      }
                    }}
                    style={{ width: '40px' }}
                    size='small' color='dark-white'>{isLoading ? <Spin indicator={<LoadingOutlined spin />} /> : t('reviews.page.reply.review.btn.ia')}</ButtonLZ>
                </div>

                <Form layout='vertical'
                  form={replyForm}
                  onFinish={submitReply}>

                  <Form.Item name='comment'>
                    <Input.TextArea
                      style={{ width: '100%', borderRadius: '15px' }}
                      className='input-field'
                      rows={6}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                  </Form.Item>
                  <a className='review-btn'
                    onClick={() => { replyForm.resetFields(); setStatus(''); }}
                  >
                    <CloseOutlined /> {t('dictionary.cancel')}
                  </a>
                  <a className='review-btn'
                    onClick={() => { replyForm.submit(); }}
                  >
                    <CheckOutlined /> {t('dictionary.accept')}
                  </a>
                  <EmojiPickerCustom  handleEmojiClick={handleEmojiClick} showPicker={showPicker} setShowPicker={setShowPicker}/>
                </Form>
              </div>
            }
          </Comment>
        </Card>
      ) :
        <div className="no-row-select">
          <img src={reviews} />
          <h1 >No tienes ninguna reseña seleccionada</h1>
          <span>Selecciona alguna para leerla</span>
        </div>
      }
    </div>
  );
}
