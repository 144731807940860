import React, { useEffect, useState } from 'react';
import CardDetailsReview from '../../../../pages/menuReviews/3_1_Reviews/Reviews/CardDetailsReview';
import Reviews from './Reviews';
import { useFetch } from '../../../../../hooks/useFetch/useFetch';
import { getProjectStorage } from '../../../../../store/storageContext';
import { useAuth } from '../../../../../store/AuthContext/AuthContext';
import moment from 'moment';
import { ForwardOutlined, LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import { Button, DatePicker, Radio, Segmented, Spin } from 'antd';

export default function ReviewsContainer() {
  
  const { groupAccount } = useAuth();

  const [selectedReview, setSelectedReview] = useState<any>(null);
  const [refreshReviews, setRefreshReviews] = useState(false);
  const [reviewsData, setReviewsData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(groupAccount);
  const [filterStatus, setFilterStatus] = useState('&status=NOT_REPLIED');
  const [filterScore, setFilterScore] = useState('');
  const [filterReference, setFilterReference] = useState('');
  const [requestParams, setRequestParams] = useState(filterStatus + filterScore + filterReference);
  const [pagination, setPagination] = useState<any>({page:1});  


  const handleReviewClick = (review: React.SetStateAction<null>) => {
    setSelectedReview(review);
  };

  useEffect(() => {    
      setRequestParams(filterStatus + filterScore + filterReference);
      setCurrentGroup(groupAccount);					
  }, [groupAccount])

  useFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + currentGroup + `/reviews?${requestParams}`
		, {}, [refreshReviews],
		(data) => {      
			setReviewsData(data.reviews);
      setPagination(data.pagination);
      setIsLoading(false);
		});

  return (
    <>           
      <div style={{ display: 'flex', justifyContent: 'space-between', width:'56%', borderRadius:'15px'}}>
          
            <Segmented
              value={filterStatus}
              options={[
                { label: 'Todas', value: '' },
                { label: 'Sin Respuesta', value: '&status=NOT_REPLIED' },
                { label: 'Respondidas', value: '&status=REPLIED' }
              ]}
              onChange={(value: any) => { setIsLoading(true); setFilterStatus(value); setRequestParams(value + filterScore + filterReference) }}
            />
            <Segmented
              value={filterScore}
              options={[
                { label: 'Todas', value: '' },
                { label: 'Positivas', value: '&score=POSITIVES' },
                { label: 'Negativas', value: '&score=NEGATIVES' }
              ]}
              onChange={(value: any) => { setIsLoading(true); setFilterScore(value); setRequestParams(filterStatus + value + filterReference) }}
            />          
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', width:'56%', marginTop:'10px'}}>

            <div>Rango <DatePicker.RangePicker format='DD-MM-YYYY' size='small' 
                        onChange={(value: any) => { 
                            let rangeFilter = value ? '&start=' + value[0].format('YYYY-MM-DD') + '&end=' + value[1].format('YYYY-MM-DD') : ''; 
                            setFilterReference(rangeFilter); 
                            setRequestParams(filterStatus + filterScore + rangeFilter) }} />
            </div>

            {reviewsData && 
              <b style={{color: 'slategrey'}}>        
                <Button type='link' onClick={()=>{setIsLoading(true); setRequestParams('page=' + (pagination.page - 1) + filterStatus + filterScore + filterReference)}} disabled={pagination.startDisabled}>    
                  <LeftCircleFilled/> 								
                </Button>				
                {pagination.from}-{pagination.to} de {pagination.total}
                <Button type='link' onClick={()=>{setIsLoading(true); setRequestParams('page=' + (pagination.page + 1) + filterStatus + filterScore + filterReference)}} disabled={pagination.endDisabled}>    
                  <RightCircleFilled/>					
                </Button>				
            </b>
          }
        </div>

        <div style={{ position: 'relative', marginTop: '0px' }} >                     
          <div>
          {!isLoading ? 
            <Reviews
              reviewsData={reviewsData}
              handleReviewClick={handleReviewClick} />
              :
              <Spin/>
          }
        </div>
        <div>          
          <CardDetailsReview reviewId={selectedReview?.name ? selectedReview?.name : null} onFinish={() => {setRefreshReviews(!refreshReviews)}} />
        </div>
      </div>
    </>
  );
}
