import { Form, Input, message, Modal, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { authFetch } from '../../../../../common/request/authFetch';
import { authRequest } from '../../../../../common/request/authRequest';

const UsersAddModal = ({
	visible,
	onFinish,
}: any) => {

	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [projects, setProjects] = useState();

	const [isLoading, setIsLoading] = useState(false);
	
	useEffect(() => {
		setIsLoading(true);
	
		authRequest(`${process.env.REACT_APP_API}/projects/projectNames`, {})
		.then((response) => {setProjects(response.map)})
		.catch(() => {message.error(t('view.errors.loadProjects'))});
		},[t]);
	
	const onFormFinish = async (values: any) => {
		const data = {
			...values,
			roles: values.roles,
			status: 'CREATED',
			appUser: values.appUser || false
		};

		await authFetch(`${process.env.REACT_APP_API}/users`, { method: 'POST', data: data });
		onFinish();
	};

	const roles = [
		{
			value:"ROLE_CLIENT",
			description:t('dictionary.client')
		},
		{
			value:"ROLE_AGENT",
			description:t('dictionary.agency')
		},
		{
			value:"ROLE_ADMIN",
			description:t('dictionary.administrator')
		}
		/*{
			value:"ROLE_APP_BASE",
			description:'Pocket Base'
		},
		{
			value:"ROLE_APP_AI",
			description:'Pocket IA'
		},
		{
			value:"ROLE_APP_WEB",
			description:'Pocket WEB'
		}*/
	]
  return (
		<Modal open={visible} onOk={() => form.submit()} onCancel={onFinish} afterClose={form.resetFields}>
			<h2>{t('dictionary.newUser')}</h2>
			<Form layout='vertical' form={form} onFinish={onFormFinish}>
				<Form.Item label={t('dictionary.email')} name='email' rules={[{ required: true, type: 'email' }]}>
					<Input />
				</Form.Item>
				<Form.Item label={t('dictionary.roles')} name='roles' rules={[{ required: true }]}>					
					<Select mode="multiple">
						{roles.map((element: any, index:any) =>
							<Select.Option key={index} value={element.value}>
								{element.description}
							</Select.Option>
						)}
					</Select>
				</Form.Item>
				<Form.Item label={t('dictionary.project')} name='project' rules={[{ required: true }]}>
				<Select>
					{
						projects && Object.keys(projects).map(project => {
							return (
							<Select.Option key={project} value={projects[project]}>
								{
									project
								}
							</Select.Option>)
						})
					}
				</Select>
				</Form.Item>
				<Form.Item name='appUser'>
					<Switch checkedChildren='UNI' unCheckedChildren='MULTI'/>
				</Form.Item>
			</Form>
		</Modal>
	)
}

export default UsersAddModal;