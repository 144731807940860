import { CaretRightOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Divider, Drawer, Tag, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../../../hooks/useFetch/useFetch';
import { getProjectStorage } from '../../../../../store/storageContext';
import { LOGSTYPES } from '../../../../../common/constants/logsTypes';
import moment from 'moment';


const Operations = (props: {job : any, onFinish: any}) => {

  const { t } = useTranslation();
  const [updateData, setUpdateData] = useState(false);
  const [successList, setSuccessList] = useState([]);
  const [errorList, setErrorList] = useState([]);  
  
  const { isLoading } = useFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/jobs/' + props.job.id + '/operations', {}, [updateData], (data) => {
		setSuccessList(data.success);
		setErrorList(data.error);
    if (!data.sync) {
			setTimeout(() => {
				setUpdateData(!updateData);
			}, 3000);
		}
	});

  return (
          <Drawer
            title={t('dictionary.operations')}
            width={'40%'}
            onClose={props.onFinish}
            open={true}
          >
                    
          <h4>{props.job.action + ' [' + props.job.operations + ' ' + t('dictionary.operations').toLowerCase() + ']'}</h4>
          <small style={{color:'grey', padding:'15px'}}>{moment(props.job.created).format('ll')}</small>
          <br/><br/>
          <Tag color="green">{t('dictionary.correct.plural.female')}: {successList.length}</Tag>
          <Tag color="red">{t('dictionary.errors')}: {errorList.length}</Tag> 
          
          <Divider orientation="left">{t('dictionary.detail')}</Divider>
          {
            errorList && errorList.map((errorItem : any) => {
              return (
                <Tooltip overlay={errorItem.tooltip ? errorItem.tooltip : null}>
                    <div style={{color:'red'}}>
                      <CloseCircleTwoTone twoToneColor="#eb2f96" style={{ paddingRight: '5px' }} />
                      {errorItem.message}
                    </div>
                  </Tooltip>
              )           
            })
          }
          {
            successList && successList.map((succesItem : any) => {
              return (
                <Tooltip overlay={succesItem.tooltip ? succesItem.tooltip : null}>
                    <div>
                      <CheckCircleTwoTone twoToneColor="#52c41a" style={{paddingRight:'5px'}}/>                  
                      {succesItem.message}
                    </div>
                    {succesItem.link &&  (<><CaretRightOutlined style={{paddingLeft:'25px'}} />
                    <a href={succesItem.link} target={'_blank'}> {(succesItem.type !== LOGSTYPES.PHOTO_COVER || succesItem.type !== LOGSTYPES.PHOTO_LOGO) ? t('dictionary.postLink') : t('dictionary.googleLink')}</a>
                    </>)}                  
                </Tooltip>
              )
            })
          }
         
          </Drawer>
	)
}

export default Operations;