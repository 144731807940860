
import { AppstoreAddOutlined, BarsOutlined, BugOutlined, DoubleLeftOutlined, DoubleRightOutlined, GlobalOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Layout as AntdLayout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import { TechMenuRoutes } from '../../../routing/TechMenuRoutes';

const { Sider, Content } = AntdLayout;
const grey = '#bab6b6';
const black = "#051235";

export const TechLayout = () => {

	const location = useLocation();
  	const pathName = location.pathname;
	const { collapsed, setCollapsed } = useAuth();
	const { t } = useTranslation();		

	return (
		<AntdLayout>			
			<Sider width={140} className='container-space' collapsed={collapsed} collapsible={true} trigger={null}>	
				<aside>
					<nav>
						<ul>
							<Link to="/tech/services">
								<li className={`${(pathName == '/tech/services') ? 'item-selected' : 'item-no-selected' }${` item-space`}`}>
									<div>
										<BarsOutlined style={{color: (pathName == '/tech/services') ? black : grey }} className='icon-menu-item'/>
										<span className={`${(pathName == '/tetch/services') ? 'selected' : 'no-selected' }`}>{t('dictionary.technology.subMenus.services')}</span>
									</div>
								</li>
							</Link>
							<Link to="/tech/jobs">
								<li className={`${(pathName == '/tech/jobs') ? 'item-selected' : 'item-no-selected' }${` item-space`}`}>
									<div>
										<BugOutlined style={{color: (pathName == '/tech/jobs') ? black : grey }} className='icon-menu-item'/>
										<span className={`${(pathName == '/tetch/jobs') ? 'selected' : 'no-selected' }`}>Errores</span>
									</div>
								</li>
							</Link>
							<Link to="/tech/externalWebs">
								<li className={`${(pathName == '/tech/externalWebs') ? 'item-selected' : 'item-no-selected' }${` item-space`}`}>
									<div>
										<GlobalOutlined style={{color: (pathName == '/tech/externalWebs') ? black : grey }} className='icon-menu-item'/>
										<span className={`${(pathName == '/tetch/externalWebs') ? 'selected' : 'no-selected' }`}>Webs</span>
									</div>
								</li>
							</Link>	
						</ul>
					</nav>
				</aside>
			</Sider>
			
			<Content className='container-space'>
				<TechMenuRoutes />				
			</Content>
			
		</AntdLayout>
	)
};