import { Input } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import React from 'react';

export const TableFilter = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
}: FilterDropdownProps) => {
  return (
      <Input
          autoFocus
          placeholder="Texto a filtrar"
          value={selectedKeys[0]}
          onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
          }}
          onPressEnter={() => {
              confirm();
          }}
          onBlur={() => {
              confirm();
          }}
      ></Input>
  );
}