import { CheckCircleFilled, ExclamationCircleFilled, InfoCircleFilled, WarningFilled } from "@ant-design/icons";

export const ActionAlert = (props : {severity: string, description: string}) => {

  const SEVERITY: any = {
    'success' : { color: 'green', icon: <CheckCircleFilled style={{fontSize: '17px'}}/> },
    'warning': { color: 'darkorange', icon: <WarningFilled style={{fontSize: '17px'}}/> },
    'critical': { color: 'red', icon: <ExclamationCircleFilled style={{fontSize: '17px'}}/> },
    'info': { color: 'DodgerBlue', icon: <InfoCircleFilled style={{fontSize: '17px'}}/> }
  };

  return (
    <>      
        <div style={{color:SEVERITY[props.severity].color}}>
        {SEVERITY[props.severity].icon} <span style={{color:'grey'}}> {props.description}</span>
        </div>          
    </>    
  )
};