import { useEffect, useState } from "react";
import { Button, Card, Drawer, Modal, Radio, Switch, Tag, Tooltip, message } from "antd";
import { useTranslation } from "react-i18next";
import { authRequest } from "../../../../../common/request/authRequest";
import { getProjectStorage } from "../../../../../store/storageContext";
import { useAuth } from "../../../../../store/AuthContext/AuthContext";
import GroupAccount from "../../../register/GroupAccount";
import { AddressGroup } from "../../../../components/model/business/FieldsGroup";
import { ButtonLZ } from "../../../../components/design/Buttons/ButtonLZ";
import { NewKeywordModal } from "./NewKeywordModal";
import { DeleteFilled, DeleteOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { authFetch } from "../../../../../common/request/authFetch";

export const KeywordsDrawer = (props: { locationId: string, onFinish: any }) => {    

    const [location, setLocation] = useState<any>();    
    const [domainTerms, setDomainTerms] = useState<any>();    
    const [locationTerms, setLocationTerms] = useState<any>();    
    const [updateData, setUpdateData] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const { t } = useTranslation();
    
    useEffect(() => {
    
        authRequest(
          `${process.env.REACT_APP_API}/projects/${getProjectStorage()}/seo/${props.locationId}`,
          { method: 'GET' }
        )
          .then((data) => {
            setLocation(data.location);
            setDomainTerms(data.domainTerms);
            setLocationTerms(data.locationTerms);
          })
          .catch(() => {
            message.error(t('view.errors.loadLocation'))
          });            
      }, [updateData]);    
    
      const deleteReply = async (term: string, domainTerm: boolean): Promise<void> => {

        const data = {
            locationId: props.locationId,
            keyword: term,
            domainTerm: domainTerm
        };

        await authFetch(
          `${process.env.REACT_APP_API}/projects/${getProjectStorage()}/seo/${props.locationId}/terms`,
            { method: 'DELETE', data }
        )
            .then((res) => {
                if (res) {
                    message.success('Keyword borrada de forma satisfactoria', 3);
                    setUpdateData(!updateData)
                    //props.onFinish();
                } else {
                  message.error('Problema al borrar la keyword', 3);
                }
            })
        }    

    return (        
        <Drawer
            placement="right"
            width={'45%'}
            open={true}            
            onClose={props.onFinish}
            bodyStyle={{ background: '#F4F7FA' }}
            title={<h2 style={{color:'slategray'}}>Configuración de <b>Keywords</b></h2>}>                                  

              <Card>
                  <h2 style={{color:'slategray'}}><b>{location?.title}</b></h2>
                  {location && <> {location.addressLine ? 
                      <i style={{color:'grey'}}>{`${location?.addressLine}, ${location?.locality}, ${location?.administrativeArea}`}</i>
                      : 
                      <i style={{color:'grey'}}>Sin ubicación</i>}</>
                  }
              </Card>

              <br/>
              <Card>
              {(!domainTerms && !domainTerms) ? 
                location && <h2 style={{color:'black'}}><b>¡ No hay keywords configuradas !</b></h2>
                :
                <h2 style={{color:'black'}}><b>Listado de keywords</b></h2>
              }

            { domainTerms?.map((term: any) => {
                  return  <div style={{ display: 'flex', justifyContent:'space-between', alignItems: 'center', margin:'10px 60px' }}>                            
                    <div style={{ display: 'flex', justifyContent:'space-between', alignItems:'initial'}}>
                      <div>
                      <Tag style={{borderRadius:'8px', border:'solid 1px'}}><b>{term.name}</b></Tag>
                        <small style={{color:'LightSteelBlue'}}><b>Dominio</b></small>
                      </div>
                    </div>                            
                    <div>                               
                    
                      <Button shape='round' size='small' type='link' style={{ color: 'grey' }} 
                          onClick={() => {
                                Modal.confirm({
                                    title: 'Confirmas que quieres eliminar esta keyword',
                                    okText: t('dictionary.confirm'),
                                    cancelText: t('dictionary.cancel'),
                                    onOk: () => deleteReply(term.name, true)
                                })
                            }}>
                        <DeleteOutlined style={{ fontSize: '12px' }} />
                        <small> Eliminar keyword</small>
                      </Button>
                    </div>
                  </div>
                })
            }           
            
            { locationTerms?.map((term: any) => {
                return  <div style={{ display: 'flex', justifyContent:'space-between', alignItems: 'center', margin:'10px 60px' }}>
                  <div style={{ display: 'flex', justifyContent:'space-between', alignItems:'initial'}}>
                      <div>
                      <Tag style={{borderRadius:'8px', border:'solid 1px'}}><b>{term.name}</b></Tag>
                        <small style={{color:'LightSteelBlue'}}><b>Ubicación</b></small>
                      </div>
                    </div>                            
                    
                    <div>                               
                      <Button shape='round' size='small' type='link' style={{ color: 'grey' }} 
                      onClick={() => {
                                Modal.confirm({
                                    title: 'Confirmas que quieres eliminar esta keyword',
                                    okText: t('dictionary.confirm'),
                                    cancelText: t('dictionary.cancel'),
                                    onOk: () => deleteReply(term.name, false)
                                })
                            }}>
                        <DeleteOutlined style={{ fontSize: '12px' }} />
                        <small> Eliminar keyword</small>
                      </Button>
                    </div>
                  </div>
              })              
            }           
          </Card>
          <br/>
          <div style={{ display: 'flex', justifyContent:'flex-end', gap: '10px', alignItems: 'center', marginTop: '16px', marginBottom: '16px' }}>
            <ButtonLZ onClick={() => { setOpenModal(true) }} >
                Añadir keyword
            </ButtonLZ>
          </div>       
            {openModal && <NewKeywordModal locationId={location.name} hasWeb={!!location.websiteUri} onFinish={() => { setUpdateData(!updateData); setOpenModal(false); }} />} 
        </Drawer>        
    );
};