import { PictureOutlined } from '@ant-design/icons';
import { Alert, Modal } from 'antd';
import { useState } from 'react';

import { useFetch } from '../../../../hooks/useFetch/useFetch';
import { getProjectStorage } from '../../../../store/storageContext';

export const VERIFY_STATUS: any = {
    "metadata": "Atributos",		
    "serviceItems": "Servicios",
	"title": "Nombre"	
};

export const GoogleUpdateModal = ({
	locationId,
	onCancel,
}: any) => {
		
	const { data }: any = useFetch(
        `${process.env.REACT_APP_API}/projects/${getProjectStorage()}/googleUpdates/${locationId}/fields`,
        {},
        [],
    );

	return (
		<Modal open={true} footer={null} onCancel={onCancel} width={'60%'}>

			<h2 style={{ paddingTop: '5px', paddingBottom: '5px', paddingRight: '15px' }}>Actualizaciones de Google en tu ficha</h2>
			<Alert message='Ves a Google Business Profile y acepta o rechaza los cambios' type="info" showIcon />
			
			<br/>
			<p>Las actualizaciones son relativas a los siguientes campos:</p>
			<br/>
			<ul>
			{
				data.map((item: string) => {
					return <li key={item}>{VERIFY_STATUS[item]}</li>;
				})
			}			
			<div></div>
		</ul>

		</Modal>
	)
}