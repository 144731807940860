import { Card, Collapse, Tabs, Typography, Modal, Tag, Input, Button } from "antd"
import { ButtonLZ } from "../../../components/design/Buttons/ButtonLZ"
import { useState } from "react";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { useAuth } from "../../../../store/AuthContext/AuthContext";
import { useTranslation } from "react-i18next";
import { EditOutlined, DeleteOutlined, SearchOutlined, KeyOutlined } from "@ant-design/icons";
import { authFetch } from "../../../../common/request/authFetch";
import { getProjectStorage } from "../../../../store/storageContext";
import { Location } from "../../../components/model/business/FieldsGroup";
import TableLZM from "../../../components/design/table/TableLZM";
import { KeywordsDrawer } from "../pageLocations/Drawer/KeywordsDrawer";


const { Paragraph } = Typography;

export const LocalSEO = () => {

    const [locations, setLocations] = useState(false);
    const [updateData, setUpdateData] = useState(false);
    const [keywordsDrawer, setKeywordsDrawer] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { groupAccount } = useAuth();
    const { t } = useTranslation();
    
    useFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/seo/' + groupAccount + '/locations',
		{},
		[updateData],
		(data) => {
			setLocations(data);        
            setIsLoading(false);        
		}
	);

    const SEO_COLUMNS: any = [		
		{
			title: 'Establecimiento',
			key: 'location',			
			width: '400px',
			//sorter: (a: any, b: any) => a.location.locality?.localeCompare(b.location.locality),
			filterDropdown: ({
			  setSelectedKeys,
			  selectedKeys,
			  confirm,			  
			}: any) => {
			  return (
				<Input
				  autoFocus
				  //placeholder="Filtro"
				  value={selectedKeys[0]}
				  onChange={(e: any) => {
					setSelectedKeys(e.target.value ? [e.target.value] : []);
					confirm({ closeDropdown: false });
				  }}
				  onPressEnter={() => {
					confirm();
				  }}
				  onBlur={() => {
					confirm();
				  }}
				></Input>
			  );
			},
			filterIcon: () => {
			  return <SearchOutlined />;
			},
			onFilter: (value: any, record: any) => {
			  return record.location.title?.toLowerCase().includes(value?.toLowerCase()) 
			  || record.location?.administrativeArea?.toLowerCase().includes(value?.toLowerCase())
			  || record.location?.locality?.toLowerCase().includes(value?.toLowerCase());
			},
			render: (text: string, record: any) => (
				<Location record={record.location}/>									
			)			
		},
		{
			title: 'keywords',
			key: 'terms',			
			render: (text: string, record: any) => (
				<div>													                
                    { record.domainTerms?.map((term: any) => {
                            return <Tag style={{borderRadius:'8px', border:'solid 1px'}}><b>{term.name}</b></Tag>                                                                        
                        })
                    }        
                    { record.locationTerms?.map((term: any) => {
                            return <Tag style={{borderRadius:'8px', border:'solid 1px'}}><b>{term.name}</b></Tag>                                                                        
                        })
                    }        
				</div>
			)
		},
		{
			title: '',
			key: 'locationId',			
			render: (text: string, record: any) => (
				<div style={{display:'flex', justifyContent:'space-around'}}>													                
                    <Button onClick={() => {setKeywordsDrawer(record.location?.composeId);}} size='small' style={{ margin:'5px', borderRadius: '20px', background:'var(--primary)',  border:`solid 1.3px RoyalBlue`, color: "white" }}>    
                        <KeyOutlined style={{ color: 'white' }}/> keywords
                    </Button>					
				</div>
			)
		}
	]

    return (
        <>
        <Card className='main-card-content'>
            <h1 className='title-card'>SEO Local <Tag color='purple'>MÓDULO EXTRA: SEO</Tag></h1>
            
            <TableLZM
					rowKey='locationName'
					scroll={{ x: true }}
					columns={SEO_COLUMNS}
					dataSource={locations}
					loading={isLoading}
				/>
        </Card>
        {keywordsDrawer && <KeywordsDrawer locationId={keywordsDrawer} onFinish={() => {setKeywordsDrawer(null);}}/>}										
        </>
    )
}