import { AlertOutlined, AlertTwoTone, ArrowRightOutlined, BarsOutlined, BellFilled, BellOutlined, CheckCircleFilled, CheckCircleTwoTone, ClockCircleOutlined, EditOutlined, FileImageOutlined, GoogleCircleFilled, GoogleOutlined, InfoCircleFilled, InfoCircleOutlined, KeyOutlined, LineChartOutlined, LoadingOutlined, QuestionOutlined, SafetyCertificateOutlined, SearchOutlined, SecurityScanTwoTone, ShopOutlined, SyncOutlined, ToTopOutlined, WarningTwoTone } from '@ant-design/icons';
import { Badge, Button, Card, Col, Input, Progress, Radio, Row, Segmented, Switch, Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { useContext, useState } from 'react';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { getProjectStorage } from '../../../../store/storageContext';
import { AddressGroup, Categories, ContactGroup, Hours, Location, NameGroup, StoreCodeGroup } from '../../../components/model/business/FieldsGroup';
import {GoogleEditPanel} from './GoogleEditPanel';
import './GoogleLocations.css';
import TableLZM from '../../../components/design/table/TableLZM';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import LogoPanel from './LogoPanel';
import PortadaPanel from './PortadaPanel';
import { DrawerContext } from '../../../../store/DrawerContext/DrawerContext';
import { GlobalVariableContext } from '../../../../store/GlobalVariableContext/GlobalVariableContext';
import { Synchronized } from '../../../components/design/Synchronized/Synchronized';
import { SCHEDULEDJOB } from '../../../../common/constants/services';
import { CategoriesView } from '../../../components/model/location/info/category/CategoriesView';
import { AlertsView } from '../../common/reports/AlertsView';
import { BlockInfoKpis } from '../../menuReports/blocks/BlockInfoKpis';
import { useIsAuthorized } from '../../common/roles/Roles';
import { STATUS } from '../../../components/model/business/OpenForBusiness';
import { GoogleUpdateModal } from './GoogleUpdateModal';
import ScheduleTab from '../../../components/model/tabsDrawer/ScheduleTab';
import { RegularDayScheduleView } from '../../../components/model/business/forms/RegularDayScheduleView';
import { KeywordsDrawer } from './Drawer/KeywordsDrawer';

export const GoogleBusiness = () => {

	const { t } = useTranslation();
	const { groupAccount } = useAuth();
	const [editPanelVisible, setEditPanelVisible] = useState(false);
	const [logoPanelVisible, setLogoPanelVisible] = useState(false);
	const [portadaPanel, setPortadaPanel] = useState(false);
	const [googleUpdatedModal, setGoogleUpdatedModal] = useState(null);
	const [updateData, setUpdateData] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
	const [indeterminate, setIndeterminate] = useState(false);
	const [locationsData, setLocationsData] = useState([]);
	
	const isAuthorized = useIsAuthorized();
	const { setLogsDrawer } = useContext(DrawerContext);

	const [isLoading, setIsLoading] = useState(true);

	const [filterStatus, setFilterStatus] = useState('HAS_VOICE_OF_MERCHANT');
	const [kpis, setKpis] = useState<any>();	

	const { setLocationDrawer } = useContext(DrawerContext)
	const { updateGlobalData } = useContext(GlobalVariableContext)	

	const isUserAuthorized = isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_INFO_EDIT']);

	const EXTRA_COLUMNS: any = {
		"CATEGORY": {
						title: 'Categorías',
						key: 'categories',						
						width: '300px',
						render: (text: string, record: any) => (<Categories categories={record.location.categories} />)
					},	
		"CONTACT": 	{
						title: t('dictionary.contact'),
						key: 'contact',
						sorter: (a: any, b: any) => a.location.websiteUri.localeCompare(b.location.websiteUri),
						render: (text: string, record: any) => (<ContactGroup record={record.location} />)
					},
		"HOURS": 	{
						title: 'Horarios',
						key: 'hours',						
						width: '400px',
						render: (text: string, record: any) => (<Hours location={record.location} />)
					}
	};

	useFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/locations'
		+ `?status=${filterStatus}`,//&locality=${filterLocality}
		{},
		[updateData, updateGlobalData],
		(data) => {
			setIsLoading(false);
			setLocationsData(data.locations);			
			//setSyncStatesPercentatge(data.syncStatesPercentatge);
			setKpis(data.kpis);
		}
	);

	const START_COLUMNS: any = [		
		{
			title: 'Establecimiento',
			key: 'location',			
			width: '400px',
			//sorter: (a: any, b: any) => a.location.locality?.localeCompare(b.location.locality),
			filterDropdown: ({
			  setSelectedKeys,
			  selectedKeys,
			  confirm,			  
			}: FilterDropdownProps) => {
			  return (
				<Input
				  autoFocus
				  //placeholder="Filtro"
				  value={selectedKeys[0]}
				  onChange={(e: any) => {
					setSelectedKeys(e.target.value ? [e.target.value] : []);
					confirm({ closeDropdown: false });
				  }}
				  onPressEnter={() => {
					confirm();
				  }}
				  onBlur={() => {
					confirm();
				  }}
				></Input>
			  );
			},
			filterIcon: () => {
			  return <SearchOutlined />;
			},
			onFilter: (value: any, record: any) => {
			  return record.location.title?.toLowerCase().includes(value?.toLowerCase()) 
			  || record.location?.administrativeArea?.toLowerCase().includes(value?.toLowerCase())
			  || record.location?.locality?.toLowerCase().includes(value?.toLowerCase());
			},
			render: (text: string, record: any) => (
				<>
					{record.location.metadata?.hasGoogleUpdated && 												
						<a onClick={() => setGoogleUpdatedModal(record.location.name)}><Tag style={{margin:'2px', borderRadius:'5px'}} color='orange'><SafetyCertificateOutlined/> Actualizaciones de Google en tu ficha
						</Tag></a>
					}					
					{record.location.metadata?.hasPendingEdits && <Tag style={{margin:'2px', borderRadius:'5px'}}><ClockCircleOutlined/> Cambios pendientes de validar por Google</Tag>}									
					<Location record={record.location}/>									
				</>
			)			
		}
	]

	const END_COLUMNS: any = [							
		{
			title: 'Optimización',
			align: 'center',
			key: 'completedRatio',
			render: (text: string, record: any) => {				
				return  <Progress width={50} type="circle" percent={Math.round(record.completedRatio.ratio * 100) } />					
			},
			sorter: (a: any, b: any) => a.completedRatio.ratio - b.completedRatio.ratio,
		},
		{
			title: 'Alertas',
			key: 'alerts',
			dataIndex: 'completedRatio',
			sorter: (a: any, b: any) => a.completedRatio.ratio - b.completedRatio.ratio,
			render: (text: string, record: any) => {				
				return record.completedRatio.alerts.length > 0 && 				
					<AlertsView alerts={record.completedRatio.alerts}/>
			}
		},
		{
			title: '',
			key: 'locationId',			
			render: (text: string, record: any) => (
				<div style={{display:'flex', justifyContent:'space-around'}}>										
					<Button onClick={() => {setLocationDrawer(record.location.composeId);}} size='small' style={{ margin:'5px', borderRadius: '20px', background:'var(--primary)',  border:`solid 1.3px RoyalBlue`, color: "white" }}>    
						<ShopOutlined style={{ color: 'white' }}/> Ver más
					</Button>										
				</div>
			)
		}
	]

	const [extraColumn, setExtraColumn] = useState('CATEGORY');
	const [columns, setColumns] = useState([...START_COLUMNS, EXTRA_COLUMNS[extraColumn], ...END_COLUMNS]);

	const onSelectChange = (selectedRowKeys: any) => {
		setSelectedRowKeys(selectedRowKeys);
		setIndeterminate(true);
	}

	const onSelectAllCustom = (selected: boolean) => {
		setSelectedRowKeys(selected ? locationsData.map((row: any) => row.locationName) : []);
		setIndeterminate(false);
	}

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		columnTitle: <Checkbox indeterminate={indeterminate} onChange={(e) => onSelectAllCustom(e.target.checked)}></Checkbox>,
		getCheckboxProps: (record: any) => ({
			disabled: !record.location.status,
			locationName: record.locationName,
		}),
	}

	const hasSelected = selectedRowKeys.length > 0

	/* Locales - Gestión masiva */

	return (
		<>
			<Card className='main-card-content'>
				
			<div style= {{width:'100%', display:'flex', justifyContent:'space-between'}}>
				<h1 className='title-card'>{t('subMenu.info.list')}</h1>
				<Button type='link' onClick={() => setLogsDrawer(true)}>
					Ver Registro
				</Button>		
			</div>
				<div className="card-container">
					{kpis && <BlockInfoKpis kpis={kpis}/>}
				</div>
				<div style={{ minHeight: '35px', marginBottom: '10px' }}>

					<Synchronized service={[SCHEDULEDJOB.GBP_LOCATIONS]}/>			

					<div style= {{display:'flex', justifyContent:'space-between'}}>		
						<Segmented
							size='small'
							value={extraColumn}											
							options={[
								{ value: 'CATEGORY', label: 'Categorías' },								
								{ value: 'CONTACT', label: 'Contacto' },
								{ value: 'HOURS', label: 'Horarios' }
							]}
							onChange={(value: any) => { setExtraColumn(value); setColumns([...START_COLUMNS, EXTRA_COLUMNS[value], ...END_COLUMNS]) }}
							/>  
						<Radio.Group size='small' buttonStyle="outline" value={filterStatus} onChange={(obj: any) => { setFilterStatus(obj.target.value) }}>
							<Radio.Button value="HAS_VOICE_OF_MERCHANT">{t('dictionary.verified.plural.female')}</Radio.Button>
							<Radio.Button value="HAS_BUSINESS_AUTHORITY,NOT_REGISTERED">{t('dictionary.no')} {t('dictionary.verified.plural.female')}</Radio.Button>
						</Radio.Group>														
					</div>	
					
					<div style= {{display:'flex', alignItems:'center', marginTop:'10px'}}>
						<div>
							<b style={{ color: 'black', font: '26px' }}>Masivo {`${selectedRowKeys.length} de ${locationsData.length}`}:</b>																								
						</div>										
						<Button type='link' onClick={() => setEditPanelVisible(true)} disabled={!hasSelected || !isUserAuthorized}>
							<b><EditOutlined /> {t('dictionary.edit')}</b>
						</Button>								
						<Button type='link' onClick={() => setPortadaPanel(true)} disabled={!hasSelected || !isUserAuthorized}>
							<b><FileImageOutlined /> {t('dictionary.cover')}</b>
						</Button>
						<Button type='link' onClick={() => setLogoPanelVisible(true)} disabled={!hasSelected || !isUserAuthorized}>
							<b><FileImageOutlined /> {t('dictionary.logo')}</b>
						</Button>
					</div>			
				</div>				
				
				<TableLZM
					rowKey='locationName'
					scroll={{ x: true }}
					rowSelection={rowSelection}
					columns={columns}
					//exportable={true}
					dataSource={locationsData}
					loading={isLoading}
				/>
				{editPanelVisible && (
					<GoogleEditPanel
						selectedRowKeys={selectedRowKeys}
						visible={editPanelVisible}
						onFinish={() => {
							setEditPanelVisible(false);
							setUpdateData(!updateData);
							setTimeout(() => {
								setLogsDrawer(true);
							}, 500);
						}}
						onCancel={() => {
							setEditPanelVisible(false);
						}}
					/>)
				}
				{logoPanelVisible && (
					<LogoPanel
						selectedRowKeys={selectedRowKeys}
						visible={logoPanelVisible}
						onFinish={() => {
							setLogoPanelVisible(false);
							setUpdateData(!updateData);
							setTimeout(() => {
								setLogsDrawer(true);
							}, 500);
						}}
						onCancel={() => {
							setLogoPanelVisible(false);
						}}
					/>)
				}
				{portadaPanel && (
					<PortadaPanel
						selectedRowKeys={selectedRowKeys}
						visible={portadaPanel}
						onFinish={() => {
							setPortadaPanel(false);
							setUpdateData(!updateData);
							setTimeout(() => {
								setLogsDrawer(true);
							}, 500);
						}}
						onCancel={() => {
							setPortadaPanel(false);
						}}
					/>)
				}
				{googleUpdatedModal && (
					<GoogleUpdateModal
						locationId={googleUpdatedModal}
						selectedRowKeys={selectedRowKeys}
						visible={portadaPanel}
						onFinish={() => {
							setGoogleUpdatedModal(null);
						}}
						onCancel={() => {
							setGoogleUpdatedModal(null);
						}}
					/>)
				}				
			</Card>			
		</>
	)
}