import React, { useContext, useState } from "react";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { Button, Drawer, Segmented, Tabs } from "antd";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import Reviews from "../reviews/reviews/Reviews";
import { getProjectStorage } from "../../../../store/storageContext";
import CardDetailsReview from "../../../pages/menuReviews/3_1_Reviews/Reviews/CardDetailsReview";
import { DoubleLeftOutlined, EnvironmentOutlined, LeftCircleFilled, RightCircleFilled, } from "@ant-design/icons";

export const ReviewsDrawer = () => {

    const { reviewsDrawer, setReviewsDrawer } = useContext(DrawerContext);
    const [selectedReview, setSelectedReview] = useState<any>(null);
    const [filterStatus, setFilterStatus] = useState('&status=NOT_REPLIED');
    const [filterScore, setFilterScore] = useState('');
    const [requestParams, setRequestParams] = useState(filterStatus + filterScore);
    const [isLoading, setIsLoading] = useState(true);
    const [reviewsList, setReviewsList] = useState<any>(null);
    const [location, setLocation] = useState<any>(null);
    const [pagination, setPagination] = useState<any>({page:1});  

    const handleReviewClick = (review: React.SetStateAction<null>) => {
        setSelectedReview(review);
    };

    const [updateData, setUpdateData] = useState(false)

    useFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + `/${reviewsDrawer}/reviewsDrawer?${requestParams}`
    , {}, [updateData],
    (data) => {      
        setReviewsList(data.reviews);
        setLocation(data.locationHeader);
        setPagination(data.pagination);
        setIsLoading(false);
    });

    return (
        <>
            {reviewsDrawer && (
                <Drawer
                    placement="right"
                    width={'45%'}
                    open={reviewsDrawer != null}
                    onClose={() => setReviewsDrawer(null)}
                    bodyStyle={{ background: '#F4F7FA' }}
                    title={!isLoading && 
                        <div>
                            <h2 style={{color:'slategray'}}><b>{location?.title}</b></h2>
                            {location && <> {location.addressLine ? 
                                <i style={{color:'grey'}}>{`${location?.addressLine}, ${location?.locality}, ${location?.administrativeArea}`}</i>
                                : 
                                <i style={{color:'grey'}}>Sin ubicación</i>}</>
                            }
                        </div>}>

                    <h2 style={{ marginBottom: '0px', color:'slategray' }}>Reseñas</h2>

                    <div style={{ marginRight: '10px', display: 'flex', justifyContent: 'flex-end', height: '20px' }}>
                        {selectedReview ?
                            <p style={{ cursor: 'pointer' }} onClick={() => { setSelectedReview(null) }}>
                                <DoubleLeftOutlined /> Atrás
                            </p>
                            : null}
                    </div>
                    {
                        !selectedReview ?
                            <> 
                                <div style={{ display: 'flex', justifyContent: 'space-between', borderRadius:'15px'}}>
                                    <Segmented
                                    value={filterStatus}
                                    options={[
                                        { label: 'Todas', value: '' },
                                        { label: 'Sin Respuesta', value: '&status=NOT_REPLIED' },
                                        { label: 'Respondidas', value: '&status=REPLIED' }
                                    ]}
                                    onChange={(value: any) => { setFilterStatus(value); setRequestParams(value + filterScore) }}
                                    />
                                    <Segmented
                                    value={filterScore}
                                    options={[
                                        { label: 'Todas', value: '' },
                                        { label: 'Positivas', value: '&score=POSITIVES' },
                                        { label: 'Negativas', value: '&score=NEGATIVES' }
                                    ]}
                                    onChange={(value: any) => { setIsLoading(true); setFilterScore(value); setRequestParams(filterStatus + value) }}
                                    />                                      
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', borderRadius:'15px'}}>
                                    {reviewsList && 
                                        <b style={{color: 'slategrey'}}>        
                                            <Button type='link' onClick={()=>{setIsLoading(true); setRequestParams('page=' + (pagination.page - 1) + filterStatus + filterScore)}} disabled={pagination.startDisabled}>    
                                            <LeftCircleFilled/> 								
                                            </Button>				
                                            {pagination.from}-{pagination.to} de {pagination.total}
                                            <Button type='link' onClick={()=>{setIsLoading(true); setRequestParams('page=' + (pagination.page + 1) + filterStatus + filterScore)}} disabled={pagination.endDisabled}>    
                                            <RightCircleFilled/>					
                                            </Button>				
                                        </b>
                                    }
                                </div>
                                {reviewsList &&<Reviews reviewsData={reviewsList} handleReviewClick={handleReviewClick} width='100%' />}
                            </>
                            :
                            <CardDetailsReview reviewId={selectedReview?.name} drawer={true} onFinish={() => {setSelectedReview(null); setUpdateData(!updateData)}}/>
                    }
                </Drawer>
            )}
        </>
    );
};
