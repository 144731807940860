import { Drawer, Tabs } from 'antd';
import { useState } from 'react';
import PruebasIADrawer from './PruebasIADrawer';
import { AutomateReviewsTab } from './AutomateReviewsTab';
import { useAuth } from '../../../../../../store/AuthContext/AuthContext';
import { CompanyIAGuide } from '../../../../../components/model/ia/CompanyIAGuide';

export const ConfigureIADrawer = (props: { openDrawer: boolean, setOpenDrawer: any }) => {

    const [openDrawer, setOpenDrawer] = useState(false)
    return (
        <Drawer placement="right"
            width={'40%'}
            title={<b style={{color:'lightslategray'}}>Configurar Inteligencia Artificial</b>}
            onClose={() => props.setOpenDrawer(false)} open={true}
            bodyStyle={{ background: '#F4F7FA' }}>
            
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <a onClick={() => { setOpenDrawer(true) }}>Pruébame</a>
            </div>
            <Tabs defaultActiveKey="1" type="line" style={{ width: '100%' }}>
				
				<Tabs.TabPane tab={<span className='title-tabPane'>Personalizar</span>} key="1">
                    <CompanyIAGuide />                    
                    {openDrawer && <PruebasIADrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />}
				</Tabs.TabPane>

				<Tabs.TabPane tab={<span className='title-tabPane'>Automatizar</span>} key="2">                    
                    <AutomateReviewsTab openDrawer={false} setOpenDrawer={undefined}/>                    
				</Tabs.TabPane>
			</Tabs>            
        </Drawer>
    )
}

            