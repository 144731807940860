import { useContext, useState } from "react";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { Divider, Drawer } from "antd";
import { getProjectStorage } from "../../../../store/storageContext";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { useTranslation } from "react-i18next";
import { PostScheduled } from "../posts/PostScheduled";
import { AddressGroup } from "../business/FieldsGroup";

export const ScheduledPostsDrawer = () => {

    const { scheduledPostsDrawer, setScheduledPostsDrawer } = useContext(DrawerContext);    
    const [location, setLocation] = useState<any>();
    const [scheduledPosts, setScheduledPosts] = useState([]);    

    const { t } = useTranslation();

    useFetch(`${process.env.REACT_APP_API}/projects/${getProjectStorage()}/${scheduledPostsDrawer}/postsScheduled`, {}, [],
    (data: any) => {
        setLocation(data.location);
        setScheduledPosts(data.scheduledPosts);
    });

    return (
        <>
            {scheduledPostsDrawer && (
                <Drawer
                    placement="right"
                    width={'45%'}
                    open={scheduledPostsDrawer != null}
                    onClose={() => setScheduledPostsDrawer(null)}
                    bodyStyle={{ background: '#F4F7FA' }}
                    title={
                        location && <div>
                            <h2>{location?.title}</h2>
                            <div><AddressGroup record={location}/></div>
                        </div>
                    }>

                    <h2 style={{color:'LightSlateGray'}}>Programaciones pendientes:</h2>

                    {scheduledPosts.map((p: any) => <div style={{ margin: '20px 25px' }}>
                        <PostScheduled postScheduled={p} />                          
                        <Divider />
                    </div>)}
                </Drawer>
            )}
        </>
    );
};
